.landingBodyContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.leftBodyContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  margin-bottom: 32px;
}

.videoContainer {
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10%;
  margin: 0 7% 64px 7%;
}

.videoContainer ul {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  padding: 0;
  opacity: 0.8;
}

.screenshotContainer {
  display: grid;
  margin-left: 7%;
  margin-right: 7%;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
}

.imgStyle {
  width: 100%;
}

.gridItem h3 {
  text-align: center;
}

.videoContainer li {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .leftBodyContainer {
    z-index: 2;
    margin-top: 0;
  }
  .landingBodyContainer {
    flex-direction: column;
    padding: 0 24px;
  }
  .videoContainer {
    grid-template-columns: 100%;
    row-gap: 16px;
    grid-template-rows: auto auto;
    margin-top: 24px;
  }
  .videoContainer ul {
    margin-left: 24px;
    font-size: 16px;
    line-height: 22px;
  }
  .screenshotContainer {
    grid-template-columns: 1fr;
    row-gap: 24px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
