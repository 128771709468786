.addNewLocationFormButton {
  width: 184px;
  height: 48px;
}

.addNewLocationFormButtonContainer {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  margin-top: 50px;
}

.hoursGrid {
  display: grid;
  grid-template-columns: max-content min-content auto;
  column-gap: 20px;
}

.timeRowContainer {
  display: flex;
  flex-direction: column;
}

.timeRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}

.timeColumn {
  display: flex;
  flex-grow: 2;
  flex-shrink: 0;
  margin-right: 20px;
}

.timeLabel {
  padding-top: 28px;
}

.timeSwitch {
  padding-top: 28px;
}

.timeButton {
  width: 100px;
}

@media screen and (max-width: 768px) {
  .hoursGrid {
    display: block;
  }
  .timeRow {
    width: 95%;
  }
  .timeColumn {
    margin-right: 10px;
  }
  .timeLabel {
    font-weight: bold;
  }
  .timeSwitch {
    padding: 10px 0;
  }
}
