.appStoreIconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 15%;
}

.leftLandingInnerContainer {
  justify-content: flex-end;
}

.appStoreIconLink {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .appStoreIconsContainer {
    margin-top: 24px;
    padding-left: 0;
  }
}
