.leftLandingBodyContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.leftLandingInnerContainer {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-end;
}

.title {
  font-size: 56px;
  font-family: Helvetica Neue;
  font-weight: bold;
  line-height: 88px;
  width: 100%;
  box-decoration-break: clone;
  box-shadow: inset 0 -20px 0 #ffbe18;
}
.subtitle {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  padding: 10% 0;
  opacity: 0.8;
}
.mobilePicContainer {
  display: none;
}
.subtitle ul {
  margin: 0;
  padding: 0;
}
.subtitle li {
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  .leftLandingBodyContainer {
    margin-top: 72px;
  }
  .leftLandingInnerContainer {
    width: 100%;
  }
  .leftLandingInnerContainer p {
    margin: 0 24px 0 0;
  }
  .title {
    z-index: 2;
    font-size: 28px;
    line-height: 42px;
    box-shadow: inset 0 -12px 0 #ffbe18;
  }
  .subtitle {
    z-index: 2;
    font-size: 14px;
    line-height: 22px;
    padding: 0;
  }
  .mobilePicContainer {
    display: flex;
    z-index: 1;
    align-items: center;
    width: 100%;
    margin-bottom: -24px;
  }
  img {
    max-width: 100%;
  }
}
