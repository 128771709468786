.users-page {
  margin: 126px auto;
  width: 60%;
  height: 100%;
}

.users-tabs {
  margin-bottom: 32px;
}

.users-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin-bottom: 2px;
  }

  p {
    font-size: 14px;
  }
}

.users-container {
  justify-content: flex-start;
  align-items: center;
}

.users-inner-container {
  width: 100%;

  table {
    width: 100%;
    border-spacing: 0 15px;
    text-align: left;
  }

  td,
  th {
    padding: 20px;
  }

  tbody {
    tr {
      box-shadow: 0px 2px 16px rgba(12, 13, 50, 0.07);
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 768px) {
  .users-header {
    display: block;
  }
  .users-page {
    margin: 80px auto;
    width: 90%;
    height: 100%;
  }
}
