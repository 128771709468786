.locationCardBusinessname {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
}

.locationCardDealsCount {
  color: #ff2a5f;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}
