.signupcontainer {
  display: flex;
  flex: 1;
}

.innercontainer {
  display: flex;
  justify-content: center;
}

.signupcontainer .left {
  flex: 1;
  margin-top: 8%;
}

.signupcontainer .right {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 8%;
}

.signupformcontainer {
  padding-right: 90px;
}

@media screen and (max-width: 768px) {
  .signupcontainer {
    flex-direction: column;
  }
  .signupcontainer .left {
    padding: 0 20px;
  }
  .signupformcontainer {
    padding-right: 0;
  }
}
