.termsAndConditions {
  margin: 72px 15%;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  padding: 0;
  opacity: 0.8;
}
