.accountcontainer {
  justify-content: center;
  align-items: center;
}

.accountinnercontainer {
  width: 50%;
}

.accountinnercontainer h1 {
  text-align: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .accountinnercontainer {
    width: 90%;
    padding-top: 48px;
  }
  .accountinnercontainer h1 {
    margin-bottom: 29px;
  }
}
