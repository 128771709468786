.addLocationFooterContainer {
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  background-color: #0c0d32;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
}
.addLocationFooterSpace {
  flex: 1;
}
.addLocationFooterMiddle {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addLocationFooterLoading {
  width: 100%;
}
.addLocationFooterButtonContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.addLocationFooterButton {
  margin-right: 32px !important;
  min-width: 120px !important;
}

@media screen and (max-width: 768px) {
  .addLocationFooterContainer {
    flex-direction: column;
  }
  .addLocationFooterLoading {
    width: 60%;
  }
  .addLocationFooterMiddle {
    height: 5px;
    position: absolute;
    bottom: 60px;
    background-color: #0c0d32;
    padding: 20px 0;
    width: 100%;
  }
  .addLocationFooterButtonContainer {
    justify-content: center;
  }
  .addLocationFooterButton:last-child {
    margin-right: 0 !important;
  }
}
