/**
 Label
*/

.MuiFormLabel-root.MuiInputLabel-formControl {
  color: rgba(12, 13, 50, 0.5);
  top: 9px;
}

.MuiFormLabel-root.MuiInputLabel-formControl.Mui-focused {
  color: rgba(12, 13, 50, 0.5);
}

.MuiFormLabel-root.MuiFormLabel-root.Mui-error {
  color: #f44336;
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  font-weight: 400;
  top: -2px;
}

/**
  Input
*/

.MuiInputBase-input.MuiInput-input {
  padding: 14px 0 24px;
  color: #0c0d32;
}

.MuiInputBase-root.MuiInput-underline:after {
  border-bottom: 1px solid #ff2a5f;
}

.MuiInputBase-root.MuiInput-underline.Mui-error:after {
  border-bottom-color: #f44336;
}

.MuiInputBase-root.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.MuiInputBase-input.MuiInput-input.Mui-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

/**
  Outlined Input
*/
.MuiOutlinedInput-FormControl .MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
  top: 15px;
  left: 15px;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  margin-top: 20px;
}

/**
  FormHelperText
*/

.MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
}

/**
  Select
*/

.MuiSelect-select.MuiSelect-select:focus {
  background-color: inherit;
}
