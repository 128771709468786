.formlayoutrow {
  display: flex;
  flex: 1;
}

.formlayoutcolumn {
  padding: 20px 16.5px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
