.locationInfoContainer {
  flex: 1;
}
.locationInfoSection {
  padding: 0px;
}
.locationInfoText {
  line-height: 32px;
  font-size: 15px;
}
