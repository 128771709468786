html,
body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin: 0;
  background-color: #fcfcfc;
  font-family: Helvetica Neue;
}

#root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
