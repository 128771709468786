nav {
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
}

.navigationContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navigationContainerMobile {
  display: none;
}

.navButton {
  background-color: #aea7ff;
  border-radius: 4px;
  height: 48px;
  width: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.logo {
  width: 56px;
  height: 56px;
  border-radius: 8px;
}
.logoWrapper {
  margin-left: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}
.logoWrapper h3 {
  margin: 0 0 0 16px;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 2px;
  color: black;
}
@media screen and (max-width: 768px) {
  nav {
    height: 48px;
  }
  .navigationContainer {
    display: none;
  }
  .navigationContainerMobile {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .navigationContainerDrawer {
    padding: 20px 0;
  }
  .logo {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  .logoWrapper {
    margin-left: 20px;
  }
  .logoWrapper h3 {
    font-size: 22px;
    line-height: 40px;
  }
}
