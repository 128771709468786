.dealFormTemplateContainer {
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: row;
}

.dealFormTemplateFormContainer {
  padding: 100px 5% 0 5%;
  overflow: scroll;
  height: calc(100vh - 183px);
  width: 60vw;
}

.dealFormTemplateOtherLocationsContainer {
  background-color: #f5f5f5;
  padding: 100px 5% 0 5%;
  height: calc(100vh - 183px);
  overflow: scroll;
  width: 40vw;
}

@media screen and (max-width: 768px) {
  .dealFormTemplateContainer {
    width: 100%;
    flex-direction: column;
    overflow: scroll;
  }
  .dealFormTemplateFormContainer {
    height: auto;
    width: calc(100vw - 30px);
    padding: 48px 15px 0 15px;
    overflow: initial;
  }
  .dealFormTemplateOtherLocationsContainer {
    background-color: #e5e5e5;
    height: auto;
    width: calc(100vw - 30px);
    padding: 15px 15px 150px 15px;
    overflow: initial;
  }
}
