.rightLandingBodyContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.picContainer {
  background-image: url("./phone1.png");
  flex: 1;
  width: 100%;
  min-height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-position-x: center;
  margin-top: 72px;
}

@media screen and (max-width: 768px) {
  .rightLandingBodyContainer {
    display: none;
  }
}
