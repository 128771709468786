.deals-container {
  justify-content: flex-start;
  align-items: center;
}

.deals-inner-container {
  margin: 126px auto;
  width: 65%;
  height: 100%;
}

.deals-nav h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 24px;
}

.deals-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(127, 128, 147, 0.2);
}

.deals-links {
  padding-inline-start: 0px;
}

.deals-nav ul li {
  list-style-type: none;
}

.deals-nav a {
  text-decoration: none;
}

.deals-item {
  display: inline-block;
}

.deals-item h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.deals-link-active,
.deals-link {
  display: inline;
}

.deals-link-active h6 {
  color: rgba(12, 13, 50, 1);
  display: inline-block;
}

.deals-link h6 {
  color: rgba(12, 13, 50, 0.4);
  display: inline-block;
}

.deals-link button,
.deals-link-active button {
  height: 24px;

  font-weight: 500;
  font-size: 14px;
}

.deals-link button {
  background-color: rgba(12, 13, 50, 0.4);
  border-radius: 4px;
  color: #ffffff;
  outline: none;
  border: none;
}

.deals-link-active button {
  background-color: #ff2a5f;
  border-radius: 4px;
  color: #ffffff;
  outline: none;
  border: none;
}

.deals-section {
  display: table;
  width: 100%;
  border-spacing: 0px 16px;
}

.table-header {
  display: table-row;
  height: 30px;
}

.header-col {
  display: table-cell;
  padding: 64px 32px 38px;
  font-weight: 500;
  font-size: 15px;
}

.deal-row {
  display: table-row;
  box-shadow: 0px 2px 16px rgba(12, 13, 50, 0.07);
  border-radius: 4px;
}

.deal-item {
  display: table-cell;
  padding: 30px;
  text-decoration: none;
  font-weight: normal;
  font-size: 15px;
  color: rgba(12, 13, 50, 1);
}

.deal-item-label {
  font-weight: bold;
}

.deal-item-buttons {
  display: table-cell;
  width: 15%;
}

.deal-item-premium-checkbox {
  display: table-cell;
  width: 15%;
  text-align: center;
}

.deal-item-premium-checkbox-label {
  display: none;
}

.empty-table {
  display: flex;
  color: rgba(12, 13, 50, 0.4);
  justify-content: center;
  align-items: center;
  height: 50%;
}

.deal-link {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 768px) {
  .deals-inner-container {
    margin: 50px auto;
    width: 90%;
    height: 100%;
  }
  .deal-row {
    display: block;
    padding: 20px;
  }
  .deals-section .table-header {
    display: none;
  }
  .deal-item {
    display: block;
    padding: 5px 0;
  }
  .deal-item-buttons {
    display: block;
  }
  .deal-item-premium-checkbox {
    display: block;
  }
  .deal-item-premium-checkbox-label {
    display: block;
  }
}
