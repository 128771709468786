.simpleOffersListNumber {
  font-weight: 500;
  font-size: 11px;
  color: #ff2a5f;
  padding-left: 10px;
}

.simpleOffersListTile {
  font-weight: 500;
  font-size: 24px;
  color: #0c0d32;
}

.simpleOffersListHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.simpleOffersListPendingContainer {
  padding-top: 20px;
}
