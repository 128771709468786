.loginmodal {
  position: relative;
  width: 600px;
}

.loginmodalinner {
  padding: 20px;
}

.loginmodaltop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginmodaltop .backcontainer {
  cursor: pointer;
  padding: 20px;
}

.loginmodaltop .closeicon {
  cursor: pointer;
  padding: 20px;
}

.loginmodaltop .back {
  color: #7f8093;
  font-size: 15px;
  line-height: 24px;
  margin-left: 8px;
}

.loginmodal h1 {
  font-size: 26px;
  text-align: center;
}

.loginformcontainer {
  padding: 0px 54.5px 20px 54.5px;
}

@media screen and (max-width: 768px) {
  .loginmodalinner {
    padding: 50px 10px;
    background-color: white;
  }
  .loginformcontainer {
    padding: 10px;
  }
}
