.hoursOfOperationContainer {
  flex: 1;
  padding-top: 30px;
}
.hoursOfOperationText {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  margin: 0;
}
.hoursOfOperationBodyContainer {
  display: flex;
}
.hoursOfOperationBodyDays {
  padding-right: 30px;
}
.hoursOfOperationBodyTime {
  flex: 1;
}

.hoursOfOperationGrid {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
}
