.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04);
  padding: 30px;
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
}

.footer-copyright {
  margin: 10px;
  text-decoration: none;
  font-size: 15px;
  color: dimgray;
}

.footer-link {
  margin: 10px;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  color: black;
}
