.locationsHeaderContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.locationsHeaderTitlesContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 60px;
}

.locationsHeaderAddNewContainer {
  max-width: 200px;
}

.locationsHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.locationsHeaderBusinessName {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #909090;
  opacity: 0.6;
}

.locationsHeaderTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #0c0d32;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.locationsHeaderCount {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  background-color: #ff2a5f;
  width: 32px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .locationsHeaderRow {
    flex-direction: column;
  }
  .locationsHeaderAddNewContainer {
    padding-top: 20px;
  }
}
