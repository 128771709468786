.simpleLocationCardText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0c0d32;
}

.simpleLocationCardRedemptionsText {
  font-weight: 300;
  font-size: 12px;
  color: #909090;
}

.simpleLocationCardContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.simpleLocationCardRemoveContainer {
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.simpleLocationCardRemove {
  margin-right: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #ff2a5f;
}

