.nameTitleHeaderBusinessName {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #ff2a5f;
}

.nameTitleHeaderHeaderText {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #0c0d32;
}
