.MuiButtonBase-root.accountbtn {
  margin-top: 82px;
  margin-right: 32px;
  padding: 12px 47px;
}

.emailverifyinfo {
  font-size: 12px;
  opacity: 0.3;
}

@media screen and (max-width: 768px) {
  .MuiButtonBase-root.accountbtn {
    margin-top: 10px;
  }
}
