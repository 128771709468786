.navbar {
  position: fixed;
  width: 100%;
  padding-right: 20px;
  z-index: 999;
}

.navLinkContainer {
  padding: 12px 20px;
}

.navLink {
  text-decoration: none;
  color: black;
}

.activeUrl {
  box-shadow: 0 2px 0 #ff2a5f;
  padding: 12px 0;
}
