.MuiButtonBase-root.loginbtn {
  padding: 12px 0px;
}

.loginbtn:first-of-type {
  margin-right: 32px;
  width: 55%;
}

.loginbtn:last-of-type {
  width: 45%;
}

.btnrow {
  display: flex;
  margin-top: 44px;
  padding: 0 16.5px;
}

.loginFormWrongPassword {
  padding-left: 20px;
  font-weight: bold;
  color: red;
}

.forgot-password-row {
  text-align: center;
}
