.signupform {
  display: flex;
  flex-direction: column;
  background-color: rgba(127, 128, 147, 0.03);
  padding: 60px 50px;
  border-radius: 4px;
}

.submitbtncontainer {
  display: flex;
  flex: 1;
  padding: 20px 16.5px;
  justify-content: center;
}

.MuiButtonBase-root.submitbtn {
  margin-top: 32px;
  padding: 16px 47px;
}

@media screen and (max-width: 768px) {
  .signupform {
    padding: 60px 20px;
  }
}
