.addLocationPageIndicatorContainer {
  display: flex;
}

.addLocationPageIndicatorActive {
  background-color: #ff2a5f;
  height: 4px;
  width: 104px;
}

.addLocationPageIndicatorInActive {
  opacity: 0.4;
}
