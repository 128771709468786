.adminBusinessViewContainer {
  margin: 100px 10% 0 10%;
  height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
}

.adminBusinessHeaderContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .adminBusinessFilters {
    min-width: 220px;
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
  .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    top: 0 !important;
  }
  .adminBusinessSearch {
    margin-right: 10px;
  }
}

.subHeaderBusinessesTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #0c0d32;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subHeaderBusinessesCount {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  background-color: #ff2a5f;
  width: 32px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .adminBusinessViewContainer {
    margin: 100px 5% 0 5%;
  }
}
