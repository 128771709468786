.locationContainer {
  display: flex;
  height: calc(100vh - 72px);
  margin-top: 72px;
}
.locationImagesContainer {
  flex: 3;
  background-color: lightgray;
}
.locationInfoAndDealsContainer {
  flex: 2;
  padding: 50px;
  overflow-y: scroll;
}
.locationGeneralInfoContainer {
}
.locationDealsContainer {
  margin-top: 100px;
}
.locationInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.smallHeaderText {
  color: #ff2a5f;
  font-weight: 500;
  font-size: 11px;
}
.locationInfoBody {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .locationContainer {
    height: 100%;
    flex: 1;
    flex-direction: column;
    margin-top: 48px;
  }
  .locationInfoAndDealsContainer {
    padding: 15px;
    overflow-y: initial;
  }
  .locationInfoBody {
    flex-direction: column;
  }
}
